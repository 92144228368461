export const cardStyle = {
  minWidth: '100%',
  padding: '20px 8px 0px',
};

export const contentStyle = {
  padding: '14px',
  border: '1px solid #eee',
  borderRadius: '8px',
};

export const coverStyle = {
  width: '100%',
  cursor: 'pointer',
};

export const titleStyle = {
  margin: 0,
  cursor: 'pointer',
};

export const subtitleStyle = {
  padding: '2% 0px',
  margin: '0px',
  color: '#777',
};
