import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from 'components/Header';
import Home from 'pages/Home';
import Post from 'pages/Post';

const App = () => {
  const [postId, setPostId] = useState(null);
  return (
    <>
      <Header/>
      <Routes>
        <Route path='/' element={<Home updatePost={setPostId} />} />
        <Route path='/post/:id' element={<Post postId={postId} />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </>
  );
};

export default App;