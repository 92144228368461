/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Image, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { ArrowLeftOutlined, ShareAltOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm'
import { collection, doc, getDocs, getDoc } from 'firebase/firestore';

import Footer from 'components/Footer';
import Perfil from 'components/Perfil';
import { backBtnStyle, coverStyle, dateStyle, extraStyle, labelStyle, pageStyle, postStyle, subtitleStyle } from './Post.style';
import { db } from '../firebase.js';


const Post = () => {
  let { id } = useParams();
  const location = useLocation();
  const aboutCollection = collection(db, 'about');
  const postDocument = doc(db, 'posts', id);
  const [about, setAbout] = useState(null);
  const [post, setPost] = useState(null);

  window.scrollTo({ top: 0, behavior: 'auto' });
  
  useEffect(() => {
    const loadAbout = async () => {
      const data = await getDocs(aboutCollection);
      setAbout(data.docs[0].data());
    };
    loadAbout();
  }, []);

  useEffect(() => {
    const getPostById = async () => {
      const data = await getDoc(postDocument);
      setPost(data.data());
      document.title = data.data().title;
    };
    getPostById();
  }, [id]);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: post?.title,
        images: [post?.imgUrl],
        url: `https://desorganizando.blog/${location.pathname}`,
      });
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  return (
    <Row gutter={[2, 2]} justify='center' style={pageStyle}>
      <Col md={14} xs={22} style={{ margin: '24px 15px 0 15px' }}>
        {!!post ? (
          <Space direction='vertical' style={postStyle}>
            <Row style={{ alignItems: 'baseline' }}>
              <Link to={'/'} style={{ textDecoration: 'none' }}>
                <Button type='link' style={backBtnStyle} icon={<ArrowLeftOutlined/>}/>
              </Link>
              <div style={{ color: '#777', paddingRight: '12px' }}>•</div>
              <Typography style={dateStyle}>
                { post?.publishDate }
              </Typography>
              <div style={{ color: '#777', padding: '0 6px 0 12px' }}>•</div>
              <Button type='link' icon={<ShareAltOutlined/>} onClick={handleShare}/>
            </Row>
            <Typography.Title level={2} style={{ marginTop: '0px' }}>
              { post?.title }
            </Typography.Title>
            <Typography style={subtitleStyle}>
              { post?.subtitle }
            </Typography>
            <Space direction='vertical' style={coverStyle}>
              <a href={post?.cover?.mentionUrl}>
                <Image src={post?.cover?.imgUrl} preview={false} style={{ objectFit: 'cover' }}/>
                <Typography style={labelStyle}>
                  { post?.cover?.label }
                </Typography>
              </a>
            </Space>
            <ReactMarkdown
              children={post?.text}
              remarkPlugins={[gfm]}
              rehypePlugins={[rehypeRaw]}
              style={{ marginBottom: 0 }}
              components={{
                p: Typography.Paragraph,
              }}
            />
          </Space>
        ) : (
          <Skeleton active paragraph={{ rows: 27 }}/>
        )}
      </Col>
      <Col md={6} xs={22} style={{ margin: '24px 15px 0 15px' }}>
        <Space direction='vertical' style={extraStyle}>
          {!!post ? (
            <>
            <Typography style={{ fontWeight: 700 }}>
              Assuntos
            </Typography>
            <Row style={{ rowGap: 8 }}>
              {post?.tags?.map((item) => {return (
                <Tag>{item}</Tag>
                )})}
            </Row>
            </>
          ) : (
            <Skeleton active paragraph={{ rows: 1 }}/>
            )}
        </Space>
        <Space direction='vertical' style={extraStyle}>
          {!!about ? (
            <>
            <Perfil/>
            <Typography.Paragraph style={{ color: '#777' }}>
              {about.bio}
            </Typography.Paragraph>
            </>
          ) : (
            <Skeleton active paragraph={{ rows: 6 }}/>
          )}
        </Space>
        <Footer/>
      </Col>
    </Row>
  )
};

export default Post;