export const muralStyle = {
  height: '300px',
  textAlign: 'center',
  alignContent: 'center',
  marginBottom: '16px',
  paddingTop: '16px',
};

export const muralItemStyle = {
  width: '100%',
  height: '300px',
  objectFit: 'cover',
};

export const legendStyle = {
  bottom: 0,
  padding: '8px',
  fontSize: '13px',
  opacity: 0.75
};
