import React from 'react';
import { Space, Typography } from 'antd';
import { extraStyle } from 'pages/Post.style';

const Footer = () => (
  <Space direction='vertical' style={extraStyle}>
    <Typography style={{ textAlign: 'center', fontSize: '13px' }}>
      Daniel Cândido ©2023 Todos os direitos reservados.
    </Typography>
  </Space>
);

export default Footer;
