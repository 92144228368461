import React from 'react';
import { Button, Col, Divider, Row, Space, Typography as Typ } from 'antd';
import { cardStyle, contentStyle, coverStyle, subtitleStyle, titleStyle } from './PostPreview.style';

const PostPreview = ({ cover, id, onEdit, onRead, onShare, publishDate, subtitle, text, title }) => (
  <Space direction='vertical' style={cardStyle}>
    <Space direction='vertical' style={contentStyle}>
      <Row style={{ columnGap: '16px' }}>
        <Col xs={24} md={8}>
          <img src={cover} alt={title} style={coverStyle} onClick={() => onRead(id)}/>
        </Col>
        <Col xs={24} md={15}>
          <Typ.Title level={4} style={titleStyle} onClick={() => onRead(id)}>
            { title }
          </Typ.Title>
          <Typ.Paragraph type='secondary' style={subtitleStyle} ellipsis={{ rows: 4, expandable: true, symbol: 'mais' }}>
            { subtitle }
          </Typ.Paragraph>
        </Col>
      </Row>
      <Divider style={{ margin: '8px 0 0 0' }}/>
      <Row align='middle'>
        <Col flex='auto'>
          <Typ.Text type='secondary'>
            { publishDate }
          </Typ.Text>
        </Col>
        <Col flex='120px'>
          <Button block type='link' size='small' onClick={() => onRead(id)}>
            Ler texto completo
          </Button>
        </Col>
      </Row>
    </Space>
  </Space>
);

export default PostPreview;