import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { containerStyle, textStyle } from './Header.style';

const Header = () => (
  <Space style={containerStyle}>
    <Link to={'/'} style={{ textDecoration: 'none' }}>
      <Typography.Link to='/' style={textStyle} >
        Desorganizando
      </Typography.Link>
    </Link>
  </Space>
);

export default Header;