import React from 'react';
import { Avatar, Typography } from 'antd';

const Perfil = () => (
  <div style={{ alignSelf: 'center', textAlign: 'center' }}>
    <Avatar
      size={{ xs: 80, sm: 80, md: 100, lg: 100, xl: 100, xxl: 100 }}
      src={<img alt='perfil' src={require('images/perfil-daniel.jpeg')}/>}
      />
    <Typography style={{ fontWeight: 700, marginTop: '8px' }}>
      Daniel Cândido
    </Typography>
  </div>
);

export default Perfil;