import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { legendStyle, muralStyle, muralItemStyle } from './Mural.style';

const Mural = ({ muralList }) => {
  return (
    <Carousel
      autoPlay
      dynamicHeight
      emulateTouch
      infiniteLoop
      showArrows
      showStatus
      showIndicators={false}
      showThumbs={false}
      statusFormatter={(current, total) => `${current} de ${total}`}
      style={muralStyle}
    >
      {muralList.map((item) => (
        <a href={item.authorUrl} target='_blank' rel='noreferrer'>
          <img alt={item.label} src={item.imgUrl} style={muralItemStyle}/>
          <p className='legend' style={legendStyle}>
            {item.label}
          </p>
        </a>
      ))}
    </Carousel>
  );
};

export default Mural;