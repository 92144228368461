export const containerStyle = {
  placeContent: 'center',
  backgroundColor: '#782338',
  height: '48px',
  width: '100%',
};

export const textStyle = {
  color: '#eee',
  fontFamily: 'Poiret One',
  fontSize: '22px',
  fontWeight: 700,
};
