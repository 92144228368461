import React from 'react';
import { Divider } from 'antd';

const SectionTitle = ({ title }) => (
  <div>
    <h2 style={{ fontSize: '1.2rem' }}>{ title }</h2>
    <Divider style={{ margin: '12px 0 0' }}/>
  </div>
);

export default SectionTitle;