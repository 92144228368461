import React from 'react';
import { Space, Typography } from 'antd';
import Perfil from './Perfil';

const cardStyle = {
  padding: '20px',
  border: '1px solid #eee',
  borderRadius: '8px',
  marginBottom: '16px',
  minWidth: '100%',
  columnGap: '20px',
};

const AboutMe = ({ text }) => { return (
  <Space style={cardStyle}>
    <Perfil/>
    <Typography.Paragraph
      ellipsis={{ rows: 6, expandable: true, symbol: 'mais' }}
      style={{ minWidth: '100%' }}
    >
      {text}
    </Typography.Paragraph>
  </Space>
)};

export default AboutMe;