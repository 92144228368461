export const pageStyle = {
  marginBottom: '30px',
  rowGap: '0px',
};

export const postStyle = {
  padding: '0 8px',
  rowGap: 0,
};

export const backBtnStyle = {
  color: '#777',
  textAlignLast: 'left',
};

export const dateStyle = {
  fontSize: '14px',
  color: '#777',
  marginBottom: '8px',
};

export const subtitleStyle = {
  fontSize: '16px',
  color: '#777',
  marginBottom: '24px',
};

export const coverStyle = {
  width: '100%',
  placeContent: 'center',
  marginBottom: '24px',
};

export const labelStyle = {
  fontSize: '12px',
  color: '#777',
  textAlign: 'center',
};

export const extraStyle = {
  padding: '16px 20px 20px',
  border: '1px solid #eee',
  borderRadius: '8px',
  marginBottom: '20px',
  width: '-webkit-fill-available',
};

