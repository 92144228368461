/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Skeleton } from 'antd';
import { collection, getDocs } from 'firebase/firestore';

import AboutMe from 'components/AboutMe';
import Footer from 'components/Footer';
import Mural from 'components/Mural';
import PostPreview from 'components/PostPreview';
import SectionTitle from 'components/SectionTitle';
import { db } from '../firebase';


const Home = () => {
  const aboutCollection = collection(db, 'about');
  const muralCollection = collection(db, 'mural');
  const postsCollection = collection(db, 'posts');
  const [about, setAbout] = useState(null);
  const [mural, setMural] = useState(null);
  const [posts, setPosts] = useState(null);
  let navigate = useNavigate();


  useEffect(() => {
    const loadAbout = async () => {
      const data = await getDocs(aboutCollection);
      setAbout(data.docs[0].data());
    };
    loadAbout();
  }, []);

  useEffect(() => {
    const loadMural = async () => {
      const data = await getDocs(muralCollection);
      setMural(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    loadMural();
  }, []);

  useEffect(() => {
    const loadPosts = async () => {
      const data = await getDocs(postsCollection);
      setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    loadPosts();
  }, []);

  const sharePost = (postId) => {
    console.log('SHARE:', postId);
  }

  const readPost = (postId) => {
    navigate(`/post/${postId}`);
  }

  const editPost = (postId) => {
    console.log('EDIT:', postId);
    // setOpenEdition(true);
  }

  return (
    <Row gutter={[2, 2]} justify='center' style={{ textAlign: 'left' }}>
      <Col md={13} xs={22} style={{ margin: '30px 15px 0 15px' }}>
        {!!about ? <AboutMe text={about.blog}/> : <Skeleton active/>}
        <SectionTitle title='Posts'/>
        {!!posts ? ( posts.map((item) => (
          <PostPreview
            id={item.id}
            cover={item.imgUrl}
            title={item.title}
            subtitle={item.subtitle}
            text={item.text}
            publishDate={item.publishDate}
            onEdit={editPost}
            onRead={readPost}
            onShare={sharePost}
          />
        ))) : (
          [1,2,3].map((_) => <Skeleton active/>)
        )}
      </Col>
      <Col md={7} xs={24} style={{ margin: '10px 0 0', padding: '0 18px' }}>
        <SectionTitle title='Mural'/>
        {!!mural ? (
          <div style={{ margin: '20px 8px' }}>
            <Mural muralList={mural}/>
          </div>
        ) : (
          <Skeleton active/>
        )}
        <Footer/>
      </Col>
    </Row>
  );
};

export default Home;