const { initializeApp } = require('firebase/app');
const { getAuth, GoogleAuthProvider } = require('firebase/auth');
const { getFirestore } = require('firebase/firestore');

const firebaseConfig = {
  apiKey: 'AIzaSyDtjPM9FooczNtooVYCNXjPaIyYUfi0Azg',
  authDomain: 'react-blogs-app-94296.firebaseapp.com',
  databaseURL: 'https://react-blogs-app-94296-default-rtdb.firebaseio.com',
  projectId: 'react-blogs-app-94296',
  storageBucket: 'react-blogs-app-94296.appspot.com',
  messagingSenderId: '82334294748',
  appId: '1:82334294748:web:7227345ffc4968cb1d0a19',
};

const app = initializeApp(firebaseConfig);

exports.auth = getAuth(app);
exports.db = getFirestore(app);
exports.provider = new GoogleAuthProvider();
